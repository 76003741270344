import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import ArrowRightICon from "../../components/common/Icons";
import KeyResources from "../../components/common/KeyResources";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";

import { RequestForm } from "../../components/form";
import { CardsThree } from "../homepage";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import useMedia from "use-media";

const card_one = require("../../assets/img/whitepaper_landing/whitepaper_card_1.png");
const card_two = require("../../assets/img/whitepaper_landing/whitepaper_card_2.png");
const card_three = require("../../assets/img/whitepaper_landing/whitepaper_card_3.png");
const card_four = require("../../assets/img/whitepaper_landing/whitepaper_card_4.png");
const card_five = require("../../assets/img/whitepaper_landing/whitepaper_card_5.png");
const card_six = require("../../assets/img/whitepaper_landing/whitepaper_card_6.png");
const card_seven = require("../../assets/img/whitepaper_landing/whitepaper_card_7.png");
const card_eight = require("../../assets/img/whitepaper_landing/whitepaper_card_8.png");

const topImage = require("../../assets/img/whitepaper_landing/whitepaper_header.png");

const resoucesData = [
  {
    image: card_one,
    header: "Call Deflection",
    content:
      "One of the best methods to reduce helpdesk costs and help employees get quick support.",
    url: "/conversational-ai-platform/call-deflection",
    link: "Read more",
    alt: "Deflection",
  },
  {
    image: card_two,
    header: "Innovative technology & ROI",
    content:
      "For achieving higher ROI, implement a next-gen workplace support solution.",
    url: "/conversational-ai-platform/innovative-technology",
    link: "Read more",
    alt: "innovative-technology",
  },
  {
    image: card_three,
    header: "Cost Reduction",
    url: "/conversational-ai-platform/cost-reduction",
    content:
      "An improvised helpdesk provides numerous cost benefits and savings.",
    link: "Watch Now",
    alt: "Cost Reduction",
  },

  {
    image: card_four,
    header: "General automation vs true automation",
    url: "/conversational-ai-platform/general-vs-true-automation",
    content:
      "Fully-automated solutions deliver a better user experience and increase employee productivity.",
    link: "Read more",
    alt: "General automation vs true automation",
  },
  {
    image: card_five,
    header: "Impact of AI and Automation on Helpdesk",
    url: "/conversational-ai-platform/impact-of-AI",
    content: "Untethering human resources from mindless and repetitive tasks.",
    link: "Read more",
    alt: "Impact of AI",
  },
  {
    image: card_six,
    header: "Self-service automation",
    url: "/conversational-ai-platform/self-service-automation",
    content: "Provide a modern, AI self-service experience to your employees.",
    link: "Read more",
    alt: "Self-service",
  },
  {
    image: card_seven,
    header: "Traditional Support vs Workativ Assistant",
    url: "/conversational-ai-platform/traditional-support",
    content:
      "See a difference in the workplace with a modern self-service that’s user friendly and efficient.",
    link: "Read more",
    alt: "Traditional",
  },
  {
    image: card_eight,
    header: "Automated Resolution",
    url: "/conversational-ai-platform/automated-resolution",
    content:
      "Achieve automated resolution of HR and IT support issues with AI and automation.",
    link: "Read more",
    alt: "Automated",
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Whitepapers on Conversational AI Chatbot & Automation | Employee Support Transformation"
        description="Latest whitepapers on Conversational AI and Process Automation solutions. Learn how to build chatbot with process automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Free Whitepapers on Conversational AI Chatbot & Automation | Employee Support Transformation"
        ogDescription="Latest whitepapers on Conversational AI and Process Automation solutions. Learn how to build chatbot with process automation."
        ogImage={topImage}
      />
      <Container>
        <Layout
          backgroundColor={"home_bg_header resource_bg_header "}
          logoFor="ASSISTANT"
        >
          <section
            className={`main-slider assistant_slider home_bg_header resource_bg_header`}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-r-35">
                  <div className="main-slider-left">
                    <h1>Whitepaper</h1>
                    <p>
                      AI and automation in the workplace can be a business
                      advantage, primarily when used in the right ways.
                      Organizations are leveraging modern software to shift to a
                      future-proof, sustainable, and low-cost employee support
                      solution.
                    </p>
                    <a href="/whitepaper/conversational-ai-process-automation">
                      <button className="btn_read">Read Now</button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mob_banner_slide">
                  <div className="main-slider-right assistant_right_img ">
                    <img src={topImage} alt="whitepaper" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="whitepaper_cards">
            <div className="container">
              <div className="row">
                <ul className="cards">
                  {resoucesData.map((data) => (
                    <li className="cards__item">
                      <div className="card">
                        <div className="card__image">
                          <img
                            loading="lazy"
                            src={data.image}
                            alt={data.alt}
                          ></img>
                        </div>
                        <div className="card__content">
                          <div className="card__title font-section-normal-text-medium">
                            {data.header}
                          </div>
                          <p className="card__text">{data.content}</p>
                          <div class="card_link_landing">
                            <a class="url_manipulation" href={data.url}>
                              Read more
                              <span class="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <CardsThree />
          {/* {isSmall ? null : <OnScrollPopup />} */}

          {/* <GoToPopup />
          <CookiesPoup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
